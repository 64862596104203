var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    { attrs: { value: _vm.value, persistent: "", width: "500px" } },
    [
      _c(
        "v-card",
        { attrs: { color: "#fafafa", width: "500px" } },
        [
          _c(
            "v-toolbar",
            { staticClass: "main", attrs: { flat: "", dark: "" } },
            [_c("v-toolbar-title", [_vm._v(" Statement Document Upload ")])],
            1
          ),
          _c(
            "v-container",
            { staticClass: "ma-0 px-4", attrs: { fluid: "" } },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        transition: "scale-transition",
                        "offset-y": "",
                        "nudge-top": "25",
                        "max-width": "290px",
                        "min-width": "290px",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-text-field",
                                _vm._g(
                                  _vm._b(
                                    {
                                      ref: "run_date",
                                      attrs: {
                                        label: "Run Date",
                                        type: "date",
                                        "prepend-inner-icon": "mdi-calendar",
                                        dense: "",
                                        "background-color": "#fff",
                                        outlined: "",
                                        rules: [_vm.isValidRunDate],
                                      },
                                      model: {
                                        value: _vm.run_date,
                                        callback: function ($$v) {
                                          _vm.run_date = $$v
                                        },
                                        expression: "run_date",
                                      },
                                    },
                                    "v-text-field",
                                    attrs,
                                    false
                                  ),
                                  on
                                )
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.run_dt_picker,
                        callback: function ($$v) {
                          _vm.run_dt_picker = $$v
                        },
                        expression: "run_dt_picker",
                      },
                    },
                    [
                      _c("v-date-picker", {
                        attrs: { max: _vm.today, "no-title": "" },
                        model: {
                          value: _vm.run_date,
                          callback: function ($$v) {
                            _vm.run_date = $$v
                          },
                          expression: "run_date",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "mt-n8" },
                [
                  _c("span", [_c("strong", [_vm._v("File Select:")])]),
                  _c(
                    "v-row",
                    { staticClass: "mt-1" },
                    [
                      _vm._l(_vm.currentFiles, function (file) {
                        return [
                          _c("v-checkbox", {
                            key: file.key,
                            staticClass: "ml-2",
                            attrs: { dense: "", label: file.label },
                            model: {
                              value: file.value,
                              callback: function ($$v) {
                                _vm.$set(file, "value", $$v)
                              },
                              expression: "file.value",
                            },
                          }),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "mt-n3" },
                [
                  _c("span", [_c("strong", [_vm._v("Warning:")])]),
                  _c("v-checkbox", {
                    attrs: { dense: "", label: _vm.warningText },
                    model: {
                      value: _vm.agreed,
                      callback: function ($$v) {
                        _vm.agreed = $$v
                      },
                      expression: "agreed",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-footer",
            { attrs: { color: "#fafafa" } },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "mb-3 mr-5",
                  attrs: { color: "gray", dense: "", medium: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closeProcessModal")
                    },
                  },
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mb-3 mr-5",
                  attrs: {
                    color: "success",
                    dense: "",
                    medium: "",
                    disabled: _vm.startButtonDisabled,
                    loading: _vm.loading,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.triggerStatementDocUpload()
                    },
                  },
                },
                [_vm._v(" Start ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }